.buffalo{
    min-height: 75vh;
    background: url(../../img/dot_bg.png);
    overflow: hidden;
    position: relative;


    @media (max-width: 767px) {
        padding: 0 12px;
    }
  
    .buffalo_bg_1{
        position: absolute;
        left: 5vh;
        top: 30vh;
    }
    .buffalo_bg_2{
        position: absolute;
        right: 5vh;
        top: 40vh;
    }
    .buffalo_bg_3{
        position: absolute;
        left: 15vh;
        top: 65vh;
    }
    .buffalo_bg_4{
        position: absolute;
        right: 15vh;
        top: 75vh;
    }
    .buffalo_bg_5{
        position: absolute;
        left: 5vh;
        top: 100vh;
    }
    .buffalo_bg_6{
        position: absolute;
        right: 5vh;
        bottom: 35vh;
        top: 110vh;

    }
    .buffalo_bg_7{
        position: absolute;
        left: 15vh;
        top: 135vh;

    }

    .buffalo_bg_8{
        position: absolute;
        right: 15vh;
        top: 145vh;

    }
    
    .buffalo_bg_9{
        position: absolute;
        left: 5vh;
        top: 170vh;

    }
    .buffalo_bg_10{
        position: absolute;
        right: 5vh;
        top: 180vh;

    }

    .buffalo_bg_11{
        position: absolute;
        left: 15vh;
        top: 205vh;

    }
    .buffalo_bg_12{
        position: absolute;
        right: 15vh;
        top: 215vh;

    }

    .buffalo_bg_13{
        position: absolute;
        left: 5vh;
        top: 240vh;

    }

    .buffalo_bg_14{
        position: absolute;
        right: 5vh;
        top: 250vh;

    }
    .buffalo_bg_15{
        position: absolute;
        left: 15vh;
        top: 275vh;

    }
    .buffalo_bg_16{
        position: absolute;
        right: 15vh;
        top: 285vh;

    }
    .buffalo_bg_17{
        position: absolute;
        left: 5vh;
        top: 310vh;

    }
    .buffalo_bg_18{
        position: absolute;
        right: 5vh;
        top: 320vh;

    }
    .buffalo_home_banner{
        background: url(../../img/dot_bg.png);
        background-repeat:no-repeat;
        background-position: 50%;
        height: 250px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-size: cover;
        padding-top: 45px;
        .buffalo_home_banner_header{
            display: flex;
            flex-wrap: wrap;
            font-style: normal;
            font-weight: 400;
            font-size: 23px;
            line-height: 23px;
            text-align: center;
            letter-spacing: 0.05em;
            color: #0B1339;
            // margin-top: 45px; 
            // min-width: 25vh !important;
            padding-left: 30px;
            font-family: 'Open Sans', sans-serif;
            font-family: 'Palanquin Dark', sans-serif;
            justify-content: center;

            @media (max-width: 760px) {
                padding-left: 0;
            }
        
        }
        .buffalo_home_banner_img{
            width: 34vh;
            height: 30vh;
            padding-left: 30px;
            img{
                width: 100%;
            }

            @media (max-width: 760px) {
                width: auto;
                height: auto;
                transform: scale(1.5);
                margin-right: 20px;
                img {
                    max-width: 142px;
                }
            }

            @media (max-width: 500px) {
                padding: 0
            }
        }
    }

  
}

