.product-details{

    .slick-track{
        display: flex;
    }

    .slick-prev{
        &::before{
            content: '' !important;
        }
        left: -20 !important;
      z-index: 999;
    }

    .slick-active{
        left: 0 !important;
    }
    .slick-slide:not(.slick-active){
        position: absolute !important;
    }
    .slick-next{
        &::before{
            content: '' !important;
        }
        right: -20 !important;;
      z-index: 999;
    }

    // CAROUSEL OVERRIDE
    .ant-carousel {
        .slick-dots {
            li button {
                background: #ccc !important;
                opacity: 1;
            }
        }
    }


    .custom-height{
        .slick-slide {
            min-height: 344px;
            max-height: 344px;
        }
    }

    @media (max-width: 767px) {
        .ant-modal{
            width: 100% !important;
        }

        .m-parent-container{
            flex-direction: column;
            & > div {
                width: 100% !important;
            }
        }

        .ant-carousel{
            width: 100% !important;

            .slick-track{
                width: 100% !important;
            }
        }
    }
}
