$prime: #1890ff !default;
.customer-form{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 45px;
    margin-bottom: 45px;
    min-height: 65vh;

    a{
        padding-top: 5px;
        padding-bottom: 5px; 
        color: #FDB415;
    }

    .customer-header{
        span{
            font-size: 20px;
            color: #0B1339;
            font-weight: 700;
        }
    }

    .change-password-header{
        span{
            font-weight: bold;
            font-size: 20px;
            color: #0B1339;
        }
    }

    .change-password-subheader{
        font-size: 15px;
        color: #0B1339;
        padding-bottom: 25px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding-top: 10px;
    }

    .customer-subheader{
        font-size: 15px;
        color: #576793;
        padding-bottom: 25px;
        a{
            color: #FDB415;
            padding-left: 5px;
        }
    }

    .customer-subfooter{
        font-size: 15px;
        color: #0B1339;
        margin-top: 10px;
        a{
            color: #FDB415;
            padding-left: 5px;
        }
    }

    .facebook_btn{
        margin-top: 20px;
        background-color: #FFFFFF;
        color: #0B1339;
        font-weight: 00;
        font-size: 15px;
        border-radius: 5px;
        width: 324px;
        height: 50px;
        border: 1px solid #0B1339;
        i{
            color: #4267B2;
        }
        img{
            padding-right: 5px;
        }
    }

    .customerHr{
       display: flex;
       div{
        width: 180px;
        height: 13px;
        border-bottom: 1.5px solid #C4C4C4;
       }
       span{
           padding: 0 5px;
       }
    }

    form {
        padding: 10px 25px 20px 25px;
        border-top: 1.5px solid #C4C4C4;;
        display: flex;
        flex-direction: column;

        .form-input{
            margin-top: 10px;
        }

        input{
            padding: 10px;
            width: 324px;
            height: 50px;
            background: #FFFFFF;
            border: 1px solid #0B1339;
            box-sizing: border-box;
            border-radius: 5px;
        }

        span.ant-input-affix-wrapper{
            padding: 0px;
            width: 324px;
            height: 50px;
            background: #FFFFFF;
            border: 1px solid #0B1339;
            box-sizing: border-box;
            border-radius: 5px;
            overflow: hidden;

            input{
                padding-left: 10px;
            }

            span.ant-input-suffix{
                margin-right: 8px;
                margin-left: 8px;
            }
        }

        .ant-form-item-control-inpu{
            width: inherit;
        }

        .ant-form-item-control-input-content{
            width: inherit;
        }

        .ant-form-item-control-input{
            width: inherit;
        }

        .ant-form-item-has-error {
            .ant-picker-input{
                border-color: #ff4d4f;
            }
        }
        .ant-picker-input{
            padding: 10px;
            width: 100%;
            height: 50px;
            background: #FFFFFF;
            border: 1px solid #0B1339;
            box-sizing: border-box;
            border-radius: 5px;

            input{
                padding: unset;
                width: unset;
                height: unset;
                background: #FFFFFF;
                border: unset;
                box-sizing: border-box;
                border-radius: unset;
            }

            .ant-picker-suffix {
                color: #FDB415;
                font-size: 16px;
            }
        }

        .ant-picker{
            padding: unset;
            border: 0ch;
            width: inherit;
        }

        .error-input{
            color: red

        }

        .submit_btn{
            background-color: #FDB415;
            color: #FFFFFF;
            font-weight: 600;
            font-size: 15px;
            border: 0;
        }

        .submit_btn:hover{
            background-color: #ffc83d;
        }

        .error { 
            border: 1px solid red; 
        }

        button{
            width: 100%;
            height: 50px;
            border-radius: 5px;
        }
        .ant-form-item {
            margin: 7px 0;
        }

    }

    .change_password_form{
        padding: 0 25px;
        border-top: unset ;
        width: 400px;
        input{
            padding: 10px;
            width: 100%;
            height: 50px;
            background: #FFFFFF;
            border: 1px solid #0B1339;
            box-sizing: border-box;
            border-radius: 5px;
        }
    }

    @media only screen and (max-width : 480px) {

        form{
            padding: 0 5px;

            input,span.ant-input-affix-wrapper{
                width: 287px;
            }
        }

        .customerHr{
            display: flex;
            div{
             width: 138px;
            }
         }
        .facebook_btn{
            width: 287px;
        }
    }

    @media only screen and (max-width : 400px) {
        .change_password_form{
            width: 100%;
        }
    }
}