// ALL ELEMENT OVERRIDES

.ant-modal-confirm-body {
    @media (max-width: 767px) {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
}

.ant-select-dropdown {
    overflow: auto !important;
    * {
        touch-action: pan-y;
        -webkit-overflow-scrolling: touch;
    }
    
}

.ant-dropdown-menu-item-active {
    background: lighten(#FDB415, 40%) !important;
    
    span {
        color: #FDB415;
    }

    a {
        color: #FDB415 !important;
    }

    a {
        div {
            color: #FDB415 !important;
        }
    }
}

@media only screen and (max-width : 770px) { 
    .ant-modal-confirm-content {
        margin-left: unset !important;
    }
}