.about{
    margin-top: -12px;
    @at-root #{&}__banner{
        background: #FDB415;
        img {
            height: 14vh;
        }

        padding: 20px 0;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .about__content,
    .about__content-merchant,
    .about__content-customer {
        display: flex;
        flex-direction: column;

        .content-container{
           text-align: center;
           max-width: 640px;
           margin: 0 auto;

           @media (max-width: 767px) {
            padding: 0 20px;
           }
           
            .content-header {
                font-size: 40px;
                color: #0B1339;
                font-weight: bold;
            }

            .content-text{
                margin-top: 20px;

                @media (max-width: 767px) {
                    text-align: justify;
                }
            }

            .content-sections {
                display: flex;
                flex-wrap: wrap;

               
                &.m-reverse {
                    @media (max-width: 767px) {
                        flex-direction: column-reverse;
                    }
                }

                .content-section--img,
                .content-section--detail {
                    flex-grow: 1;
                    flex-basis: 0;
                    max-height: 250px;
                    overflow: visible;

                    @media (max-width: 767px) {
                        width: 100%;
                        flex-basis: auto;
                        max-height: none;
                    }
                }

                .left-pos {
                    margin-right: 20px;
                }

                .right-pos {
                    margin-left: 20px;
                }

                @media (max-width: 767px) {
                    .right-pos,
                    .left-pos {
                        margin: 0;
                    }
                }

                .content-section--detail {
                    text-align: left;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                }

                .content-section--img {
                    display: flex;
                    // justify-content: center;
                    align-items: center;
                    img {
                        width: 100%
                    }

                    @media (max-width: 767px) {
                        margin-top:30px;
                    }
                }

            }
        }
    }

    @at-root #{&}__content {
        padding: 40px 0;
        padding-bottom: 60px;

        @media (max-width: 767px) {
            padding-bottom: 0;
        }
    }

    @at-root #{&}__content-about {
        margin-bottom: 60px;
    }

    .about__content-merchant,
    .about__content-customer {
        margin: 60px 0;
        background: #e8e8e8;
        padding: 30px 0;

        @media (max-width: 767px) {
            margin: 0;
            padding: 30px 20px;
        }
    }
    
}