$yellow: #FDB415 !default;
$black : #0B1339 !default;
$white: #ffffff !default;
$border_blue: #576793 !default;

.customer-profile{
    display: flex;
    justify-content: center;

    //CUSTOMER HISTORY
    .order_history_content{
        h1{
            text-align: center;
        }
        .header_title{
            font-weight: bold;
            font-size: 20px;
            line-height: 24px;
            color: $black;
        }
        .content_divider{
            height: 2px;
        }
        width: 877px;
        margin: 20px 0;
        border-radius: 5px;
        .order-item{
            padding: 0 10px;
            .order-content{
                font-size: 14px;
                display: flex;
                padding: 20px 10px;
                border: 1px solid $yellow;
                border-radius: 5px;
                margin-bottom: 16px;
                justify-content: space-between;
                flex-wrap: wrap;
                align-items: center;

                .store-details{
                    width: 60%;
                    display: flex;
                    img{
                        cursor: pointer;
                        min-width: 91.66px;
                        width: 91.66px;
                        height: 90.47px;
                        object-fit: cover;
                        margin-right: 5px;
                    }
                    .store-info{   
                        padding-left: 5px;
                        display: flex;
                        flex-direction: column;
                        justify-content: space-around;
                        word-break: break-word;
            
                        .store-name{
                            cursor: pointer;
                            font-size: 18px;
                            font-weight: bold
                        }
            
                    }
                }
               
                .order-details{
                    display: flex;
                    width: 35%;
                    align-items: flex-start;
                    .amount{
                        min-width: 100px;
                    }
                    .status{
                        padding-left: 10px;
                        margin-left: 10px;
                        border-left: 0.3px solid #999999;
                        justify-content: center;
                        display: flex;
                        flex-direction: column;

                        button{
                            margin-top: 5px;
                            background-color: $yellow;
                            color: white;
                            border-color: $yellow;
                            padding: 5px 10px;
                            border-radius: 5px;
                        }
                    }
                    .bold{
                        font-weight: bold;
                        font-size: 18px;
                    }
                    .order_amount{
                        white-space: nowrap;
                    }
                }

                @media only screen and (max-width : 768px){
                    .store-details{
                        width: 100%;
                    }
                    .order-details{
                        justify-content: center;
                        padding-top: 10px;
                        width: 100%;

                        .status{
                            width: 50%;
                            div{
                                text-align: center;
                            }
                        }

                        .amount{
                            width: 50%;
                            div{
                                text-align: center;
                            }
                        }
                    }
                }
            }
        }
        .pagination{
            margin: 20px;
            ul{
                display: flex;
                justify-content: center;

                .ant-pagination-item-active a {
                    color: $yellow;
                }
                .ant-pagination-item-active {
                    border-color: $yellow;
                }
            }
        }

    }

    //CUSTOMER PROFILE
    .profile-content{
        width: 900px;
        margin: 50px 0;
        border-radius: 5px;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        color: $black;

        .image-pane{
            width: 300px;
    
            .image-frame{
                width: 278px;
                height: 278px;
                border: 3px solid $yellow;
                box-sizing: border-box;
                border-radius: 28px;
                margin-bottom: 30px;
                display: flex;
                justify-content: center;
                align-items: center;
                position:relative;

                img{
                    width: 254px;
                    height: 254px;
                    object-fit: cover;
                    border-radius: 20px;
                }
            }

            .edit_btn{
                width: 278px;
                height: 35px;
                background: $white;
                border: 3px solid $yellow;
                border-radius: 28px;
                justify-content: center;
                display: flex;
                align-items: center;
                font-weight: 600;
                font-size: 14px;
                line-height: 18px;
                cursor: pointer;
            }

            .upload_btn{
                font-size: 36px;
                color: #ffffff;
                width: 50px;
                height: 50px;
                background-color: #FDB415;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 50px;
                z-index: 50;
                position: absolute;
                right: 8px;
                bottom: 4px;
                z-index: 50;
                span{
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
            }

            .edit_btn:hover{
                background: $yellow;
            }
        }

        //image
        .ant-upload-list-picture-card-container{
            width: 100%;
            height: 100%;
            object-fit: cover;
        }

        .ant-upload-list {
            width: 100%;
            height: 100%; 
        }

        .ant-upload.ant-upload-select-picture-card {
            width: unset;
            height: unset;
        }

        .ant-upload.ant-upload-select-picture-card{
            border: unset;
        }

        // .ant-btn{
        //     border: transparent;
        //     background: transparent;
        //     height: 0
        // }

        .ant-upload.ant-upload-select-picture-card > .ant-upload{
            height:unset
        }

        .upload_default{
            .ant-upload-list-picture-card{
                background: url(../img/default_image_profile.jpg);
                background-size: cover;
                border-radius: 20px;
            }
        }

        .upload_image{
            .ant-upload-list-picture-card{
                background: $white;
                border-radius: 20px;
            }
        }

        .ant-upload-list-item {
            border: unset;
            padding: unset;
        }

        .ant-upload-picture-card-wrapper{
            width: 254px;
            height: 254px;
        }

        .ant-upload-list-item-info::before{
            background-color:unset
        }

        .details-pane{
            width: 550px;
            .name-detail{
                width: 492px;
                height: 68px;
                font-weight: 700;
                font-size: 40px;
                line-height: 68px;
            }

            .other-detail{
                display: flex;
                margin-bottom: 18px;
                justify-content: space-between;
                .detail-label{
                    font-size: 16px;
                    line-height: 24px;
                    font-weight: 400;
                }

                .detail-content{
                    font-size: 14px;
                    line-height: 30px;
                    font-weight: 700;
                }

                .default{
                    padding: 2px 6px;
                    background: #3AAE37;
                    border-radius: 30px;
                    color: $white;
                    font-weight: 500;
                    font-size: 12px;
                    height: 15px;
                    margin-bottom: 2px;
                }
            }

            .whole-pane{
                display: flex;
                flex-direction: column;
                width: 100%;
            }

            .half-pane{
                width: 48%;
            }

            .edit-other-detail{
                display: flex;
                justify-content: space-between;
            }

            .margin-top{
                margin-top: 10px;
            }

            .submit-button{
                border-radius: 5px;
                width: 100%;
                height: 50px;
                font-size: 14px;
                font-weight: 600;
                margin-top: 10px;
            }

            .save-profile-btn{
                width: 48%;
            }


            .header-detail{
                color: $black;
                font-weight: 700;
                font-size: 16px;
                line-height: 20px;
                width: 100%;
                display: flex;
                justify-content: space-between;
                margin-bottom: 15px;
                align-items: center;

            }
            .address-btn{
                border-radius: 5px;
                font-weight: 600;
                font-size: 14px;
                height: 40px;;
            }
        }

        //FORM
        .ant-input{
            border-color: $border_blue;
            border-radius: 5px;
            height: 50px
        }

        .ant-input:hover{
            border-color: #e8a513;
        }

        .ant-form-item-control-input{
            width: 100%;
            height: 50px;
        }
        .ant-form-item-control-input-content{
            height: 100%;
            width: 100%;
        }

        .ant-picker{
            height: 100%;
            width: 100%;
            border-color: $border_blue;
            border-radius: 5px;
        }

        .ant-picker:hover{
            border-color: #e8a513;
        }

        .ant-picker-suffix{
            color: $yellow;
        }
    }

    @media only screen and (max-width : 860px) {

        .order_history_content{
            width: 100%;
            padding: 5px;
        }
        //CUSTOMER PROFILE
        .profile-content{
            display: flex;
            justify-content: center;
            width: 90%;

            .image-pane{
                width: unset;
            }
            .details-pane{
                .whole-pane{
                    display: flex;
                    flex-direction: column;
                }
    
                .half-pane{
                    width: 100%;
                }
    
                .edit-other-detail{
                    flex-wrap: wrap;
                    margin-bottom: unset;
                }
                
                .name-detail{
                    text-align: center;
                    margin-top: 20px;
                    width: 100%;
                    font-size: 40px;
                    line-height: 1.2;
                    height: auto;
                }

                .other-detail{
                    flex-wrap: wrap;
                    margin-bottom: unset;

                }

                .detail-content{
                    margin-bottom: 10px;
                    overflow-wrap: break-word;
                }

                .ant-divider-horizontal {
                    margin: 12px 0;
                }
            }

        }
    }
}
.order-info{
    .order-item{
        display: flex;
        justify-content: space-between;
        .font-bold{
            font-weight: bold;
            font-size: 16px;
        }
    }
    .order_divider{
        margin: 10px 0;
        height: 2px;
    }
    .order-date{
        padding-bottom: 16px;
    }
    .transaction-no{
        padding-bottom: 5px;
    }

    .ant-collapse-extra{
        margin-left: 15px;
    }
}

.order_panel{
    display: flex;
    justify-content: space-between;

    .order_panel_extra{
        white-space: nowrap;
    }
}

.order_data{
    display: flex;
    justify-content: space-between;

    label{
        margin-right: 20px;
    }

    span{
        padding: 2px 0;

        &.text-bold {
            font-weight: bold;
        }
    }
    .total{
        font-weight: bold;
    }

}

.order_icon {
    color: $yellow;
}

.custom_divider{
    margin: 6px 0 !important;
}

.padding_right{
    padding-right: 18px;
}

.ant-upload-list-picture .ant-upload-list-item-thumbnail, .ant-upload-list-picture-card .ant-upload-list-item-thumbnail{
    font-size: 18px !important;
    display: flex !important;
    justify-content: center !important;
    align-content: center !important;
    align-items: center !important;
}

.ant-upload-list-picture-card .ant-upload-list-item-progress {
    height: 20% !important;
    padding: 0 20px !important;
}

.ant-progress-bg {
    height: 6px !important;
}

.ant-upload-list-picture-card .ant-upload-list-item-uploading .ant-upload-list-item-info {
    height: 100% !important
}