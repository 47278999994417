// BG COLORS
$bg-yellow: #FDB415 !default;

// TEXT COLOR 
$white  : #ffffff;
$blue   : #0B1339;
$prime  : #FDB415;
$gray   : gray;
$red    : red;

// FLEX 
.flex-col {
    display: flex;
    flex-direction: column;
}

.flex-row {
    display: flex;
    flex-direction: row;
}

.justify-between{
    justify-content: space-between;
}

.btn {
    background: $bg-yellow;
    color: $white;
    outline: none;
    border: 0;
    padding: 10px 20px;
    font-weight: 600;
    
    &:not(:disabled) {
        cursor: pointer;

        &:hover {
            background: darken($bg-yellow, 8%);
            color: $blue;
        }
    }
}

.pos-rel {
    position: relative;
}

// COLOR 
.text-prime {
    color: $prime;
}

.text-gray {
    color: $gray;
}

.text-red {
    color: red;
}

.text-yellow {
    color: #FDB415;
}

.color-link {
    color: $prime;
    outline: none;
    cursor: pointer;

    &:hover {
        color: darken($prime, 8%);
    }
}

.stickyHeader {
    position: sticky;
    top: 0;
    left: 0;
    z-index: 999;
}

// TEXT NOWRAP
.nowrap {
    white-space: nowrap;
}


// NO USER SELECTION 
.displaySelect {
    user-select: none;
}

// CURSOR 
.c-pointer {
    cursor: pointer;
}

// PADDING 
.padding-10-0 {
    padding: 10px 0;
}

.padding-20-0 {
    padding: 20px 0;
}

// MARGIN 
.margin-t-20 {
    margin-top: 20px;
}

.margin-t-5vh {
    margin-top: 5vh;
}

.margin-t-5px {
    margin-top: 5px;
}

.margin-t-8px {
    margin-top: 8px;
}

.margin-t-15px {
    margin-top: 15px;
}

.margin-t-20px {
    margin-top: 20px;
}

.margin-l-20px {
    margin-left: 20px;
}

// FONT SIZE 
.font-10{
    font-size: 10px;
}

.font-14 {
    font-size: 14px;
}

.font-20{
    font-size: 20px;
}

.font-16 {
    font-size: 16px;
}

//FONT WEIGHT
.font-weight-bold {
    font-weight: bold;
}


.divider{
    position: relative;
    display: block;
    width: 100%;
    text-align: center;

    margin: 16px 0;
    font-weight: 600;
    font-size: 20px;
    color: #0B1339;

    padding: 10px 0;

    // &::after,
    &::before{
        position: absolute;
        bottom: 0;
        left: 0;
        content: '';
        width: 100%;
        border-top: 1px solid #FDB415 !important;
    }

    // &::after {
    //     top: auto;
    //     bottom: 0;
    // }
}

.cancel-button{
    border-radius: 5px;
    border: 2px solid #d9d9d9;
    width: 100%;
    height: 50px;
    color: #000000D9;
    font-size: 14px;
    font-weight: 600;
    margin-top: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.cancel-button-48{
    width: 48%;
}

.cancel-button-t-15{
    margin-top: 15px;
}

.cancel-button:hover{
    border: 2px solid #40A9FF;
    color: #40A9FF;
}



@media  screen and (max-width: 900px) and (orientation: landscape) {
    // MODAL
    .ant-modal{
        top: calc(100% - 350px) !important;
        bottom: auto !important;
    }
}
