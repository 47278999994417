header {
    display: initial;
}

.order{
    min-height: 75vh;
    .order_pickup_section{
        margin-top: 25px;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        flex-wrap: wrap;
        .menu_item{
            width: 600px;
            margin-right: 10px;
            .menu_header{
                padding-bottom: 10px;
                border-bottom: 0.3px solid #999999;
                .menu_header_title{
                    font-style: normal;
                    font-weight: bold;
                    font-size: 20px;
                    line-height: 16px;
                    color: #0B1339;
                }
                .menu_header_desc{
                    margin-top: 10px;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 14px;
                    line-height: 16px;
                    color: #576793;
                }
                .margin_top{
                    margin-top: 40px;
                }
            }
            .menu_content{
                margin-top: 15px;
                margin-bottom: 20px;

                .menu_item{
                    margin-top: 15px;
                    display: flex;
                    justify-content: space-between;

                    .menu_mobile{
                        display: none;
                    }

                    .menu_web{
                        display: flex;
                        img{
                            min-width: 91.66px;
                            width: 91.66px;
                            height: 90.47px;
                            object-fit: cover;
                            margin-right: 5px;
                            cursor: pointer;
                        }
                        .add_to_order{
                            background: #0B1339;
                            display: flex;
                            justify-content: center;
                            color: #FFFFFF;
                            margin-left: unset;
                            margin-right: 5px;
                            align-items: center;
                            font-size: 12px;
                            padding: 5px;
                            cursor: pointer;
                        }
                        div{
                            display: flex;
                            flex-direction: column;
                            margin-left: 5px;
                            margin-right: 5px;
                            .item_title{
                                font-style: bold;
                                font-weight: 600;
                                font-size: 14px;
                                line-height: 15px;
                                color: #000000;
                                padding-bottom: 5px;

                            }
                            .item_description{
                                font-style: normal;
                                font-weight: 300;
                                font-size: 14px;
                                color: #000000;
                                text-align: left;

                                min-height: 42px;
                               
                                overflow: hidden;
                                text-overflow: ellipsis;
                                display: -webkit-box;
                                -webkit-line-clamp: 2;
                                line-height: 20px;
                                -webkit-box-orient: vertical;
                            }
                        }
                    }

                    .item_price{
                        font-weight: 600;
                        font-size: 14px;
                        line-height: 15px;
                        color: #0B1339;
                        margin-left: 5px;
                        margin-right: 18px;
                        p{
                            white-space: nowrap;
                        }

                        @supports (-moz-appearance:none) {
                            margin-right: 25px;
                        }
                    }

                    @media only screen and (max-width : 768px) {
                        .menu_web{
                            display: none;
                        }

                        .item_price{
                            display: none;
                        }
                        .menu_mobile{
                            display: block;
                            width: 100%;
                            justify-content: center;
                            padding-bottom: 5px;
                            img{
                                width: 100%;
                                height: 175px;
                                object-fit: cover;
                                margin-right: 5px;
                                cursor: pointer;
                            }
                            .item_details{
                                display: flex;
                                flex-direction: column;
                                .item_title{
                                    font-style: bold;
                                    font-weight: 600;
                                    font-size: 18px;
                                    line-height: 25px;
                                    padding-top: 10px;
                                    color: #000000;
                                    padding-bottom: 5px;
    
                                }
                                .item_description{
                                    text-align: left;
                                    width: 100%;
                                    line-height: 1.2em;
                                    overflow: hidden;
                                    display: -webkit-box;
                                    -webkit-box-orient: vertical;
                                    -webkit-line-clamp: 3;
                                }
                                .item_btn_price{
                                    display: flex;
                                    flex-direction: row;
                                    justify-content: space-between;
                                    align-content: center;
                                    align-items: baseline;

                                    .price{
                                        font-weight: 600;
                                        font-size: 14px;
                                        color: #0B1339;
                                    }
                                }
                            }
                        }
                    }

                }

                .empty_data{
                    text-align: center;
                }
            }
            .menu_content::-webkit-scrollbar {
                -webkit-appearance: none;
            }
            
            .menu_content::-webkit-scrollbar:vertical {
                width: 11px;
            }
            
            .menu_content::-webkit-scrollbar:horizontal {
                height: 11px;
            }
            
            .menu_content::-webkit-scrollbar-thumb {
                border-radius: 8px;
                border: 2px solid white; /* should match background, can't be transparent */
                background-color: rgba(0, 0, 0, .5);
            }
        }
        
        .order_detail{
            width: 300px;
            margin-left: 10px;
            position: sticky;
            top: 85px;
            .order_service_detail{
                .service_detail_header{
                    padding: 10px 20px;
                    background: #0B1339;
                    color: #FFFFFF;
                    font-style: normal;
                    font-weight: bold;
                    font-size: 17.54px;
                    line-height: 21px;
                    text-align: center;
                    border-radius: 5px 5px 0px 0px;
                    word-break: break-word;
                    @media (max-width: 767px) {
                        border-radius: 0;
                    }
                }
                .service_detail_content{
                    border: 1px solid #576793;
                    border-radius: 0px 0px 5px 5px;
                    padding: 10px 20px;
                    .service_detai{
                        padding: 5px 0;
                        font-weight: 500;
                        font-size: 13px;
                        line-height: 15px;
                        color: #0B1339;
                    }
                    .ant-form-item{
                        margin-bottom: 0;
                    }
                    .select_time{
                        width: 100%;
                        div{
                            height: 35px;
                            color: #0B1339;
                            border-color: #0B1339;
                            border-radius: 5px;

                            input{
                                color: #0B1339;
                                height: 34px;
                            }

                            .ant-select-selection-placeholder{
                                justify-items: center;
                                align-items: center;
                                display: flex;
                            }
                        }
                        svg{
                            color: #FDB415;
                        }
                    }
                }
            }

            .promo_section{
                margin: 15px 0 5px 0;
                .promo_input{
                    margin-top: 6px;
                    height: 35px !important;
                    color: #0B1339;
                    border-color: #0B1339;
                    border-radius: 5px;
                }

                .ant-form-item {
                    margin-bottom: 0;
                }
            }

            .order_item{
                border-bottom: 0.3px solid #999999;
                padding: 10px;
                font-weight: 700;

                max-height: 47vh;//58vh;
                overflow: auto;

                .empty_data{
                    display: flex;
                    justify-content: center;
                }
            }
            .order_total{
                // display: flex;
                // justify-content: space-between;
                padding: 10px;
                font-style: normal;
                font-weight: 600;
                font-size: 18px;
                line-height: 15px;
                color: #0B1339;

                .ant-row:nth-child(2) {
                    margin-top: 3px;
                    padding-bottom: 10px;
                }
            }

            @media (max-width: 767px) {
                top: calc(100% - 105px) !important;
                background-color: #ffffff;
                transition: all 200ms ease-in-out;
                position: fixed;
                // bottom: calc(-100vh - 41px);
                top: auto;
                z-index: 999;

                .order_item{
                    max-height: calc(100vh);
                    height: calc(100vh - 25vh);
                }

                .promo_section{
                    margin: 15px 0 5px 0;
                }

                .check-out-btn {
                    font-size: 18px;
                    width: '100%';
                    margin: '5px 0';
                    cursor: pointer;
                    // background-color: #FDB415;
                    // color: #FFFFFF;
                    // height: 50px;
                    // display: flex;
                    // align-items: center;
                    // justify-content: center;
                }

                &.active-summary-view {
                    top: 0 !important;
                    bottom: 0;
                    margin-top: 0;
                    // height: 100%;
                    // overflow-y: scroll;
                    // -webkit-overflow-scrolling: touch

                    .order-summary-btn-mobile::before{
                        content: '';
                        background: #ffffff;
                        position:fixed;
                        top: 0;
                        width: 100%;
                        height: 100vh;
                        z-index: -1;
                    }
                }
            }
        }

        .order-summary-btn-mobile{
            display: none;
            * {
                pointer-events: none;
            }
        }
        @media (max-width: 767px) {
            .order-summary-btn-mobile{
                display: flex;
                align-items:center;
                justify-content: center;
                width: 100%;
                cursor: pointer;
                // position: fixed;
                // left: 0;
                // bottom: 0;
                z-index: 999;
                background: #FDB415;
                padding: 10px;
                font-size: 18px;
                font-weight: 500;
                color: #ffffff;
                text-align: center;
                // border-top-left-radius: 10px;
                // border-top-right-radius: 10px;
                span {
                    position: absolute;
                    right: 12px;
                    font-weight: normal;
                }

                span.icon {
                    right: auto;
                    left: 12px;
                }
            }
        }

        @media only screen and (max-width : 945px) {
            .menu_item{
                width: unset;
                margin-right:unset;
            }

            .order_detail{
                margin-top: 15px;
                width: 100%;
                margin-left:unset;
            }
        }

        @media only screen and (max-width : 600px) {
            .menu_item{
                width: 100%;
                padding: 5px;
            }

            padding: 0 12px;
            padding-bottom: 20px;
        }
    }

    .order_section{
        display: flex;
        justify-content: center;
        .order_item{
            width: 700px;
            padding-bottom: 30px;
            .order_header{
                font-weight: bold;
                font-size: 20px;
                line-height: 24px;
                text-align: center;
                color: #0B1339;
                padding: 25px 0;
                border-bottom: 0.3px solid #999999;
            }
            .order_sub_header{
                padding: 25px 25px 0 25px;
                display: flex;
                justify-content: space-between;
                span{
                    font-weight: bold;
                    font-size: 30px;
                    line-height: 37px;
                    color: #0B1339;
                }
                a{
                    color: #FDB415;
                    font-weight: bold;
                    padding-top: 2px;
                }
            }
            .items{
                padding: 30px 25px;
                border-bottom: 0.3px solid #999999;
                font-weight: 500;
                font-size: 15px;
                line-height: 18px;
                color: #0B1339;
                div{
                    margin-top: 5px;
                    display: flex;
                    justify-content: space-between;
                    .span{
                        display: flex;
                        flex-direction: column;
                    }
                }
                .empty_data{
                    display: flex;
                    justify-content: center;
                }
            }
            .order_total{
                padding: 10px 25px;
                display: flex;
                justify-content: space-between;
                font-weight: bold;
                font-size: 15px;
                line-height: 18px;
                color: #0B1339;
            }
            .payment-btn{
                margin-top: 10px;
                height: 50px;
                width: 100%;
                background: #FDB415;
                border-radius: 5px;
                color: #FFFFFF;
            }
        }

        @media only screen and (max-width : 768px){
            .order_item{
                width: 100%;
            }
        }
    }
}

// PLUS/MINUS BUTTONS
.cart_btn{
    display: flex;
    flex-direction: row;
    padding-top: 5px;
}
.order_details{
    display: flex;
    align-items: flex-start;
    width: 70%;
    justify-content: space-between;
    flex-wrap: nowrap;
}
.order-btn{
    margin: 0 5px 5px 5px;
    display: flex;
    justify-content: center;
    background-color: transparent;
    border: 1px solid #FDB415;
    color: #FDB415;
    cursor: pointer;
    height: 26px;
    width: 30px;
    align-items: center;

    &:hover {
        background: rgba(253, 180, 21,0.7);
        color: #fff;
    }

}
// SUBMIT BUTTONS
.order-submit-btn {
    margin: 20px 0;
    height: 40px !important;
    font-weight: 600 !important;
    width: 100%;
    background: #FDB415;
    border-radius: 5px !important;
    color: #FFFFFF;
    cursor: pointer;

    &:hover {
        background: darken(#FDB415, 8%) !important;
    }
}

.order-form{
    .ant-form-item{
        margin-bottom: 10px;
    }

    input{
        border-color: #576793;
        height: 5vh;
        border-radius: 5px;
        padding: 16px 5px !important;
    }

    .ant-select-selector{
        border-color: #576793 !important;
        height: 35px !important;
        border-radius: 5px !important;
        display: flex;
        align-items: center;
    }

    .ant-select-single .ant-select-selector .ant-select-selection-item {
        display: flex;
        align-items: center;
    }
}

.address_suggestion{
    // padding: 10px;
    // background-color: #FDB415;
    // border-radius: 5px;
    // margin: 10px 0;
    // cursor: pointer;
    
    padding: 10px;
    // margin: 10px 0;
    cursor: pointer;
    border-bottom: 1px solid gray;
}

.first_address_suggestion{
    border-top: 1px solid gray;
}

.address_suggestion:hover{
    background-color: #e8a513;
}

.edit-btn{
    color: #ffffff !important;
    background: #FDB415 !important;
    height: 46px !important;
    border-radius: 5px !important;
    margin-bottom: 5px !important;
    font-weight: 600 !important;
    min-width: 118px;
    cursor: pointer;

    &:hover {
        background: darken(#FDB415, 8%) !important;
    }
}

.promo{
    margin-top: 5px;
}

.verify-btn{
    color: #ffffff !important;
    background: #FDB415 !important;
    height: 46px !important;
    border-radius: 5px !important;
    margin-bottom: 6px !important;
    font-weight: 600 !important;
    min-width: 118px;
    cursor: pointer;

    &:hover {
        background: darken(#FDB415, 8%) !important;
    }
}


.order_address{
    display: flex !important;
    justify-content: left !important;
    align-items: flex-end !important;
}

.verify_promo{
    display: flex !important;
    justify-content: left !important;
    align-items: flex-end !important;
}

.select_birthday{
    width: 100% !important;
    color: #0B1339;
    border-radius: 5px;
    height: 32px;
    margin-bottom: 10px;
    border-color: #576793 !important;
    min-height: 32px;
    border-radius: 5px !important;
    div{
        input{
            color: #0B1339;
            height: 5vh;
        }
    }
    .ant-picker-clear{
        color: #FDB415;
    }
    .ant-picker-input .ant-picker-suffix {
        color: #FDB415;
    }
}

// .ant-btn-primary {
//     background-color: #FDB415 !important;
//     border-color: #FDB415 !important;
// }

.ant-modal-body{
    padding: 24px !important;
}

.menu_price{
    font-weight: bold;
    color: #000000;
    font-size: 20px;
}

.age_confirmation_text{
    font-size: 16px;
    text-align: justify;
}




