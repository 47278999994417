.checkout{
    margin-top: 20px;
    min-height: 75vh;

    @media (max-width: 767px) {
        padding: 0 12px;
        padding-bottom: 20px;
    }
    
    .checkout_section{
        margin-top: 25px;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;

        .mobile-donation-card{
            display: none;
        }

        .web-donation-card{
            display: block;
        }

        .checkout_order_detail{
            width: 600px;
            margin-right: 10px;
            .checkout_header{
                font-style: normal;
                font-weight: bold;
                font-size: 20px;
                line-height: 16px;
                color: #0B1339;
            }
            .checkout_note{
                margin-top: 15px;
                background-color: #FDB415;
                padding: 10px;
                display: flex;
                align-content: center;
                align-items: baseline;
                border-radius: 5px;
            }
            .checkout_detail_info{
                margin-top: 15px;
                width: 100%;
                border-radius: 5px;
                border: 1px solid #576793;
                .checkout_detail_header{
                    font-size: 26px;
                    padding: 10px 15px;
                    border-bottom: 1px solid #576793;
                }
                .checkout_detail_content{
                    padding: 10px 15px;
                    font-size: 16px;
                    .switch_account{
                        padding-left: 32px;
                        a{
                            color: #FDB415;
                        }
                    }
                    .menu_name {
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        width: 440px;
                    }
                }
                .checkout_divider{
                    background: #576793;
                    margin: 15px 0;
                }
                .font-weight-bold{
                    font-weight: bold;
                    font-size: 18px;
                }
            }

            .checkout_sub_header{
                font-weight: bold;
                margin-top: 15px;
                font-size: 16px;
            }

            .checkout_form{
                margin-top: 15px;
                width: 100%;
                border-radius: 5px;
                border: 1px solid #576793;
                font-size: 16px;
                .form_header{
                    align-items: center;
                    display: flex;
                    justify-content: space-between;
                    font-weight: bold;
                    padding: 10px 15px;
                    border-bottom: 1px solid #576793;
                }
                .form_body{
                    margin: 10px;   
                }
            }
            form{
                .ant-form-item{
                    margin-bottom: 10px;
                }
            
                input{
                    border-color: #576793;
                    height: 5vh;
                    border-radius: 5px;
                    padding: 16px 5px !important;
                }
                .card_info{
                    display: flex;
                    justify-content: space-between;

                    .center{
                        margin: 0 4px;
                    }
                   
                }
                .datePicker{
                    // width: 23vh;
                    border-color: #576793;
                    border-radius: 5px;
                    height: 5vh;
                }

                .submit_btn{
                    margin: 20px 0;
                    width: 100%;
                    background-color: #FDB415;
                    border-color: #FDB415;
                    border-radius: 5px;
                    height: 40px;
                }

            }
        }
        
        .checkout_detail{
            width: 300px;
            margin-left: 10px;
            .order_service_detail{
                .service_detail_header{
                    padding: 10px 20px;
                    background: #0B1339;
                    color: #FFFFFF;
                    font-style: normal;
                    font-weight: bold;
                    font-size: 17.54px;
                    line-height: 21px;
                    text-align: center;
                    border-radius: 5px 5px 0px 0px;
                    word-break: break-word;
                }
                .service_detail_content{
                    border: 1px solid #576793;
                    border-radius: 0px 0px 5px 5px;
                    padding: 10px 20px;
                    div{
                        padding: 5px 0;
                        font-weight: 500;
                        font-size: 13px;
                        line-height: 15px;
                        color: #0B1339;
                    }
                }
            }
        }
        @media only screen and (max-width : 945px) {
            .mobile-donation-card{
                display: block;
            }

            .web-donation-card{
                display: none;
            }

            .checkout_order_detail{
                width: unset;
                width: 100%;
                margin-right:unset;
                
                form .card_info{
                    flex-direction: column;
                    input{
                        width: 100% !important;
                    }
                    .datePicker{
                        width: 100%;
                    }
                    .center{
                        margin: unset;
                    }
                }
                
            }

            .checkout_detail{
                margin-top: 15px;
                width: 100%;
                margin-left:unset;
            }
        }

    }

    .checkout_stripe{
        margin-bottom: 15px;
        .pay_bottom{
            margin-top: 15px;
        }

        .input-feild{
            border: 1px solid #576793;
            height: auto;
            border-radius: 5px;
            padding: 10px 5px 10px 5px !important;
        }

        .input-feild-focus{
            border: 1px solid #576793;
            height: auto;
            border-radius: 5px;
            padding: 10px 5px 10px 5px !important;
            box-shadow: 0px 0px 1px 1px #e8d4a7;
        }

        .input-feild-error{
            border: 1px solid #ff4d4f;
            height: auto;
            border-radius: 5px;
            padding: 10px 5px 10px 5px !important;
            // box-shadow: 0px 0px 1px 1px #e8d4a7;
        }

        .input-feild-error-focus{
            border: 1px solid #ff4d4f;
            height: auto;
            border-radius: 5px;
            padding: 10px 5px 10px 5px !important;
            box-shadow: 0px 0px 1px 1px #e8d4a7;
        }
    }
}