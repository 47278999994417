.restaurant{
    &:not(.home-resto) {
        min-height: 75vh;
    }
    
    .restaurant_banner{
        background-repeat:no-repeat;
        background-position: center;
        height: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .restaurant_detail_section{
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-content: center;
        margin-bottom: 20px;
        .restaurant_detail{
            width: 600px;
            box-shadow: 0px 0px 13px rgba(0, 0, 0, 0.15);
            border-radius: 8px;
            margin-right: 10px;
            .detail_header{
                display: flex;
                border-bottom: 0.3px solid #999999;
                margin: 0 15px;
                .detail_tab{
                    padding: 10px 0;
                    padding-right: 30px;
                    font-style: normal;
                    font-weight: 500;
                    font-size: 15.88px;
                    line-height: 19px;
                    color: #0B1339;
                    a{
                        color: #0B1339;
                    }
                }
            }
            .restaurant_content{
                margin: 0 15px;
                .restaurant_title{
                    margin: 20px 0;
                    display: flex;
                    word-break: break-word;
                    img{
                        width: 89.5px;
                        height: 89.5px;
                        object-fit: cover;
                        margin-right: 10px;
                        cursor: pointer;
                    }
                    div{
                        margin-left: 10px;
                        display: flex;
                        flex-direction: column;
                        // justify-content: space-between;
                        // align-content: space-between;
                        .title{
                            font-style: normal;
                            font-weight: bold;
                            font-size: 34px;
                            line-height: 35px;

                            color: #0B1339;
                        }
                        .address{
                            padding-top: 5px;
                            font-style: normal;
                            font-weight: normal;
                            font-size: 14px;
                            line-height: 16px;
                            color: #576793;
                        }
                    }
                }
                .restaurant_tags{
                    color: #000000;
                    flex-wrap: wrap;
                    margin-top: 5px;
                    color: #000000;
                    display: flex;
                    flex-direction: row;
                    margin-left: unset;
                    align-items: center;
                    span{
                        margin-left: 10px;
                        padding: 5px 10px;
                        font-size: 14px;
                        border: 1px solid #FDB415;
                        box-sizing: border-box;
                        border-radius: 32px;
                        color: #0B1339;
                        margin-bottom: 5px;
                    }
                }

                .restaurant_description{
                    margin-top: 15px;
                    font-size: 14px;
                    line-height: 18px;
                    font-weight: 300;
                    color: #000000;
                    text-align: left;
                }

                .menu_header{
                    margin-top: 15px;
                    padding-bottom: 10px;
                    font-style: normal;
                    font-weight: bold;
                    font-size: 20px;
                    line-height: 24px;
                    color: #0B1339;
                    border-bottom: 0.3px solid #999999;
                }

                .menu_content{
                    margin-top: 15px;
                    margin-bottom: 20px;
                    .menu_item{
                        margin-top: 15px;
                        display: flex;
                        justify-content: space-between;

                    
                        .menu_mobile{
                            display: none;
                        }

                        .menu_web{
                            display: block;
                            display: flex;
                            img{
                                min-width: 91.66px;
                                width: 91.66px;
                                height: 90.47px;
                                object-fit: cover;
                                margin-right: 5px;
                                cursor: pointer;
                            }
                            div{
                                display: flex;
                                flex-direction: column;
                                margin-left: 5px;
                                margin-right: 5px;
                                .item_title{
                                    font-style: bold;
                                    font-weight: 600;
                                    font-size: 14px;
                                    line-height: 15px;
                                    color: #000000;
                                    padding-bottom: 5px;
    
                                }
                                .item_description{
                                    font-style: normal;
                                    font-weight: 300;
                                    font-size: 14px;
                                    line-height: 15px;
                                    color: #000000;
                                    text-align: left;
                                    // max-width: 375px;

                                    min-height: 42px;
                               
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                    display: -webkit-box;
                                    -webkit-line-clamp: 3;
                                    line-height: 18px;
                                    -webkit-box-orient: vertical;
                                }
                            }
                        }

                        @media only screen and (max-width : 768px) {
                            .menu_web{
                                display: none;
                            }
                            .menu_mobile{
                                display: block;
                                width: 100%;
                                justify-content: center;
                                padding-bottom: 5px;
                                img{
                                    width: 100%;
                                    height: 175px;
                                    object-fit: cover;
                                    margin-right: 5px;
                                    cursor: pointer;
                                }
                                div{
                                    display: flex;
                                    flex-direction: column;
                                    .item_title{
                                        font-style: bold;
                                        font-weight: 600;
                                        font-size: 18px;
                                        line-height: 25px;
                                        padding-top: 10px;
                                        color: #000000;
                                        padding-bottom: 5px;
        
                                    }
                                    .item_description{
                                        text-align: left;
                                        width: 100%;
                                        line-height: 1.2em;
                                        overflow: hidden;
                                        display: -webkit-box;
                                        -webkit-box-orient: vertical;
                                        -webkit-line-clamp: 3;
                                    }
                                }
                            }
                        }
                    }

                    .empty_data{
                        text-align: center;
                    }
                }
                .menu_content::-webkit-scrollbar {
                    -webkit-appearance: none;
                }
                
                .menu_content::-webkit-scrollbar:vertical {
                    width: 11px;
                }
                
                .menu_content::-webkit-scrollbar:horizontal {
                    height: 11px;
                }
                
                .menu_content::-webkit-scrollbar-thumb {
                    border-radius: 8px;
                    border: 2px solid white; /* should match background, can't be transparent */
                    background-color: rgba(0, 0, 0, .5);
                }
            }
        }
        
        .restaurant_service{
            margin-left: 10px;
            width: 364px;
            .service_item{
                // width: 364px;
                box-shadow: 0px 0px 13px rgba(0, 0, 0, 0.15);
                border-radius: 8px;
                padding-bottom: 10px;
                .service_item_header{
                    padding: 10px 0;
                    padding-right: 30px;
                    font-style: normal;
                    font-weight: 500;
                    font-size: 15.88px;
                    line-height: 19px;
                    color: #0B1339;
                    border-bottom: 0.3px solid #999999;
                    margin: 0 15px;
                    display: flex;
                    justify-content: center;
                }
                .service_item_content{
                    display: flex;
                    justify-content: center;
                    margin-top: 15px;

                    form{
                        .ant-form-item{
                            width: 216px;
                        }
                        .ant-form-item{
                            margin-bottom: 10px;
                        }
                        .select_date{
                            width: 100%;
                            height: 35px;
                            color: #0B1339;
                            // border: 1px solid #0b1339;
                            border-radius: 5px;
                            div{
                                input{
                                    color: #0B1339;
                                }
                            }
                            svg{
                                color: #FDB415;
                            }
                        }
                        .select_guest{
                            div{
                                width: 100%;
                                height: 35px;
                                color: #0B1339;
                                border: 1px solid #999999;
                                border-radius: 5px;
                            }
                            .ant-select-arrow{
                                color: #FDB415;
                            } 
                        }
                        .select_time{
                            width: 100%;
                            height: 35px;
                            color: #0B1339;
                            // border: 1px solid #999999;
                            border-radius: 5px;
    
                            div{
                                input{
                                    color: #0B1339;
                                }
                            }
                            svg{
                                color: #FDB415;
                            }
                        }
                        .submit_btn{
                            background: #FDB415;
                            font-weight: 600 !important;
                            border-radius: 5px;
                            width: 100%;
                            height: 35px;
                            color: #FFFFFF;
                            border-color: #FDB415;

                            &:hover {
                                background: darken(#FDB415, 8%) !important;
                            }
                        }
                    }
                }

                @media (max-width: 767px) {
                    display: none;
                }
            }
            .margin_top{
                margin-top: 20px;
            }
        }

        .more_info{
            margin-top: 20px;
            padding: 10px 0;
            border: 1px solid #0B1339;
            display: flex;
            flex-direction: column;
            align-items: center;
            word-break: break-word;

            .more_info_header{
                font-size: 20px;
                line-height: 19px;
                padding: 15px;
                color: #0B1339;
                font-weight: bold;
            }
            .more_info_content{
                padding: 5px 15px;
                display: flex;
                flex-wrap: wrap;

                .btn {
                    background: #FDB415 !important; 
                    height: 40px !important;
                    color: #FFFFFF; 
                    margin: 10px;
                    padding-top: 4px;
                    min-width: 100px;
                    text-align: center;
                    border-radius: 4px !important;
                    font-weight: 600 !important;
                    cursor: pointer;

                    &:hover {
                        background: darken(#FDB415, 8%) !important;
                    }
                }
                pre {
                    font-size: 12px;
                    text-align: left;
                    white-space: pre-line;
                    color: #0B1339;
                    padding-left: 10px;
                    line-height: 1.7;
                    margin-top: -4px;
                }
                .social-link{
                    font-size: 30px;
                    color: #0B1339;
                    cursor: pointer;
                    margin: 0 8px;
                }
                .social-instagram {
                    margin-left:10px;
                }
            }
        }

        @media only screen and (max-width : 1007px) {
            .restaurant_detail{
                margin-right: unset;
            }
            .restaurant_service{
                padding: 5px;
                margin-top: 20px;
                width: 600px;
                margin-left: 0;
                .service_item{  
                    .service_item_content{
                        form{
                            display: flex;
                            justify-content: center;
                            flex-direction: column;
                            align-items: center;
                            width: 100%;
                            .ant-form-item{
                                width: 90%;
                            }
                        }
                    }
                }
            }
            .more_info{
                width: 100%;

                .more_info_header{
                    text-align: center;
                }
            }
        }

        @media only screen and (max-width : 600px) {
            .restaurant_detail{
                width: 100%
            }
        }
      
    }

    .restaurant_section{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        // flex-wrap: wrap;
        flex-direction: column;
        .search_section{
            padding-right: 10px;
            padding-top: 20px;
            width: 200px;

            form{
                .ant-form-item{
                    width: 190px;
                }
                .search_option{
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    border-bottom: 0.3px solid #999999;
    
                    .search{
                        border: 1px solid #0B1339;
                        box-sizing: border-box;
                        border-radius: 5px;
                        height: 35px;
                    }
                    .option_title{
                        width: 100%;
                        padding-top: 15px;
                        padding-bottom: 10px;
                        font-style: normal;
                        font-weight: 600;
                        font-size: 14px;
                        line-height: 16px;
                        color: #0B1339;
                    }
                    .option_btn{
                        margin-bottom: 15px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        width: 100%;
                        height: 35px;
                        border: 1px solid #0B1339;
                        border-radius: 5px;
                        font-style: normal;
                        font-weight: 500;
                        font-size: 13px;
                        line-height: 16px;
                        color: #0B1339;
                        cursor: pointer;
                    }
    
                    .option_btn_active{
                        margin-bottom: 15px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        width: 100%;
                        height: 35px;
                        background: #FDB415;
                        border-radius: 5px;
                        font-style: normal;
                        font-weight: 500;
                        font-size: 13px;
                        line-height: 16px;
                        color: #0B1339;
                        cursor: pointer;
                    }

                    .searchbtn{
                        width: 100%;
                        margin-top: 15px;
                        height: 35px;
                        border-radius: 5px;
                        background-color: #0B1339;
                        border-color: #0B1339;
                    }

                }
                .select_guest{
                    div{
                        width: 100%;
                        color: #0B1339;
                        border-radius: 5px; 
                        border-color: #0B1339;
                        height: 35px;
                    }
                    .ant-select-arrow{
                        color: #FDB415;
                    } 
                    .ant-select-selection-search-input {
                        height: 35px;
                    }                    
                }

                .select_date{
                    width: 100%;
                    color: #0B1339;
                    // border-color: #0B1339;
                    border-radius: 5px;
                    height: 35px;
                    div{
                        input{
                            color: #0B1339;
                        }
                    }
                    .ant-picker-clear{
                        color: #FDB415;
                    }

                    svg{
                        color: #FDB415;
                    }
                }
                .padding_top{
                    padding-top: 15px;
                }

                .select_time{
                    width: 100%;
                    border-radius: 5px;
                    height: 35px;

                    div{
                        input{
                            color: #0B1339;
                        }
                    }
                    svg{
                        color: #FDB415;
                    }
                }

                .ant-form-item{
                    margin-bottom: 15px;
                }

                .border_unset{
                    border: unset;
                }
                .price_min{
                 
                    text-align: center;
                    border-radius: 5px 0 0 5px;
                    border-color: #0B1339;
                    height: 35px;
                    border-right: 0;
                }
                .price_max{
                    
                    text-align: center;
                    border-radius: 0 5px 5px 0;
                    border-color: #0B1339;
                    border-left: 0;
                    height: 35px;
                }
                .price_input_split{
                    width: 30%;
                    border-left: 0;
                    border-right: 0;
                    pointer-events: none;
                    border-color: #0B1339;
                    color: #FDB415;
                    height: 35px;
                }
                .ant-input-group-compact{
                    display: flex;
                    justify-content: center;
                }
            }

            .ant-select-selector{
                border-radius: 5px;
                border-color: #0b1339!important;
            }
        }
        .resto_section{
            max-width: 985px;
            justify-content: flex-start;
            align-items: flex-start;
            flex-wrap: wrap;
            z-index: 5;
            
            &.space-more {
                padding-left:40px;

                @media (max-width: 767px) {
                    padding-left: 0;
                }
            }

            .resto_content{
                padding: 25px 0;
                // display: flex; 
                // border-bottom: 0.3px solid #999999;
                // ADDEDD: 3/5/2021 
                max-width: 195.89px;
                font-size: 12px;

                @media (max-width: 767px) {
                    padding: 0 20px;
                    padding-top: 25px;
                    max-width: none;
                }

                img{
                    width: 195.89px;
                    min-width: 195.89px;
                    height: 195.89px;
                    object-fit: cover;
                    border-radius: 5px;
                    cursor: pointer;
                    // margin-right: 10px;

                    @media (max-width: 767px) {
                        width: 100%;
                        min-width: auto;
                    }
                }

                .resto_content-details{
                    margin-top: 10px !important;
                    word-break: break-word !important;
                }

                .resto_content-title {
                    height: 84px;
                    @media (max-width: 767px) {
                        height: auto;
                    }
                }
                .resto_name{
                    cursor: pointer;
                    font-style: normal;
                    font-weight: bold;
                    font-size: 20px;
                    line-height: 24px;
                    color: #0B1339;
                    min-height: 48px;
                    word-wrap: break-word;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                }
                .resto_address{
                    padding-top: 5px;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 14px;
                    line-height: 16px;
                    min-height: 37px;
                    color: #576793;

                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                }
                .resto_description{
                    margin-top: 5px;
                    font-size: 14px;
                    // line-height: 18px;
                    font-weight: 300;
                    color: #000000;
                    text-align: justify;

                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 2; /* number of lines to show */
                    -webkit-box-orient: vertical;
                    min-height: 46px;
                }
                .sub_title{
                    margin-top: 5px;
                    font-style: bold;
                    font-weight: 600;
                    font-size: 14px;
                    // line-height: 15px;
                    color: #000000;
                    padding-bottom: 5px;

                }
                .item_description{
                    font-style: normal;
                    margin-top: 5px;
                    font-weight: 300;
                    font-size: 14px;
                    color: #000000;
                    text-align: left;
                    margin-left: unset;

                    min-height: 42px;
                           
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 3;
                    line-height: 18px;
                    -webkit-box-orient: vertical;
                }

                .view_more{
                    color: #FDB415;
                    cursor: pointer;
                }
                .restaurant_tags{
                    // flex-wrap: wrap;
                    color: #000000;
                    margin: 8px 0;
                    // align-items: center;
                    span{
                        // margin-left: 10px;
                        // padding: 5px;
                        margin-top: 5px;
                        font-size: 14px;
                        // border: 1px solid #FDB415;
                        box-sizing: border-box;
                        // border-radius: 32px;
                        color: #0B1339;

                        // ADDED: 3/8/2021
                        overflow: hidden;
                        text-overflow: ellipsis;
                        display: -webkit-box;
                        -webkit-line-clamp: 2;
                        line-height: 18px;
                        -webkit-box-orient: vertical;
                        min-height: 36px;

                        // ADDED: 3/5/2021
                        font-size: 10.5px;
                        // white-space: nowrap;
                    }

                    label {
                        font-weight: 600;
                        display: block;
                        font-size: 14px;
                    }

                    .tags{
                        font-size: 14px;
                    }
                }
                .restaurant_availability{
                    color: #000000;
                    flex-wrap: wrap;
                    margin-top: 10px;
                    display: flex;
                    flex-direction: row;
                    font-size: 14px;
                    margin-left: unset;
                    align-items: center;
                    max-width: 195.89px;
                    span{
                        // margin-left: 10px;
                        padding: 5px 0;
                        border: 1px solid #FDB415;
                        box-sizing: border-box;
                        font-size: 10.5px;
                        border-radius: 32px;
                        color: #0B1339;
                        background-color: #FDB415;
                        margin-bottom: 5px;
                        cursor: pointer;
                        width: 62px;
                        text-align: center;
                    }

                    span + span {
                        margin-left: 4px;
                    }

                    span:nth-child(4) {
                        margin-left: 0;
                    }
                }
                .restaurant_availability :hover{
                    background-color: #e8a513;
                }
            }
            .pagination{
                margin: 25px;
                ul{
                    display: flex;
                    justify-content: center;

                    .ant-pagination-item-active a {
                        color: #FDB415;
                    }
                    .ant-pagination-item-active {
                        border-color: #FDB415;
                    }
                }
            }
            // h1{
            //     // justify-content: center;
            //     // display: flex;
            //     margin: 0 auto;
            //     padding: 20px 0;
            // }

            @media (max-width: 767px) {
                & > .ant-space-item{
                    width: 100%;
                    // margin-right: 0 !important;
                }
            }
        }

        @media only screen and (max-width : 835px){
            .search_section{
                margin: 0 10px;
                padding-right: unset;
                width: 100%;
                form{
                    .ant-form-item{
                        width: 100%;
                    }
                }
            }
            .resto_section{
                width: 100%;
                padding-left: unset;
            }
        }
    }

    // .ant-select-selector {
    //     height: 33px !important;
    //     border-radius: 5px !important;
    //     border: 1px solid #999999 !important;
    //     border-color: #0b1339!important;
    // }
}
.availability_section{
    .available_time{
        padding: 10px;
        background-color: #FDB415;
        border-radius: 5px;
        margin: 10px 0;
    }

    .available_time:hover{
        background-color: #e8a513;
    }
}
.ant-modal-title{
    justify-content: center;
    display: flex;
    font-size: 18px !important;
}

.ant-modal-confirm-body {
    @media only screen and (max-width : 600px) { 
        .ant-modal-confirm-content {
            font-size: 12px !important;
        }
        .anticon {
            margin-right: 6px !important;
        }
    }

}
// .ant-tabs-nav {
//     width: 0 ;
// }

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #FDB415 !important;
}

.ant-tabs-nav .ant-tabs-tab:hover {
    color: #000000 !important;
}

.ant-tabs-nav .ant-tabs-tab {
    color:#000000 !important;
}

.ant-tabs-ink-bar{
    background-color: #FDB415 !important;
}

@media only screen and (max-width : 970px) {
    .restaurant .restaurant_section .resto_section{
        width: 748px;
    }
    .restaurant .restaurant_section .resto_section.space-more {
        padding-left: 40px;
    }
   
}

@media only screen and (max-width : 767px) {
    .restaurant .restaurant_section .resto_section{
        width: 100%;
    }
    .restaurant .restaurant_section .resto_section.space-more {
        padding-left: unset;
    }
    .restaurant .restaurant_section .resto_section > .ant-space-item {
        margin-right: unset !important;
    }
}

@media only screen and (max-width : 504px){
    .image_carousel{
        display: contents;
    }
}




