$white: #ffffff !default;

.footer_web{
    display: block;
}

.footer_mobile{
    display: none;
}

.footer{
    display: flex;
    background: #545456;
    color:white;
    position: relative;
    justify-content: center;
    align-items: center;
    height: 100px;

    @at-root #{&}__center{
        display: flex;
        flex-direction: column;

        .footer_row{
            display: flex;
            justify-content: center;
            padding: 0 5px;
        }

        .footer_row_new{
            display: flex;
            justify-content: space-between;
            padding: 0 5px;
        }

        .footer_tab{
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            // padding: 0 18px;
            font-size: 12px;
            a{
                color: white;
                white-space: nowrap;
                font-size: 14px;
            }
            p{
                margin: 0;
                color: #B9B9B9;
            }

            .hidden-xs{
                display: block;
            }
            .visible-xs {
                display: none;
            }

            @media (max-width: 767px) {
                font-size: 14px;
                .hidden-xs{
                    display: none;
                }
                .visible-xs {
                    display: block;
                }
            }
        }
    }
}

@media only screen and (max-width : 600px) {
    .footer_web{
        display: none;
    }
    .footer_mobile{
        display: block;
    }
    .footer{
        height: 125px;
    }
}