.search_section{
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #ffffff;
    z-index: 10;
    position: relative;
    background: transparent;
    

    .search_web{
        display: block;
    }
    .search_mobile{
        display: none;
    }

    .home_search{
        background-color: #ffffff;
        z-index: 5;
        display: flex;
        flex-direction: column;
        box-shadow: 0px 2px 10px 2px rgba(0, 0, 0, 0.15);
        width: 877px;
        
        .search_header{
            display: flex;
            border-bottom: 0.5px solid #FDB415;
            padding: 0 16px;

            .search_tab_active,
            .search_tab{
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;
                padding: 10px 10px;
                font-style: normal;
                font-weight: 500;
                font-size: 18px;
                line-height: 16px;
                color: #0B1339;

                span{
                    padding-right: 5px;
                }
                .center_tab{
                    // border-right: 0.8px solid #0B1339;
                    padding: 10px 10px 10px 0;
                }
                .center_tab_icon{
                    border-left: 0.8px solid #0B1339;
                    padding: 10px 5px 10px 20px;
                    // padding: 10px;
                    // 10px 6px 10px 10px;
                }
            }

            .search_tab:first-child {
                padding-left: 0;
            }

            .search_tab_active{
                color: #FDB415;
            }
        }

        .search_content{
            border-radius: 5px;
            // margin: 20px 25px 0 25px;

            form{
                width: 100%;
                // display: flex;
                // justify-content: center;
            }
            .pick-up {
                width: 120px;
            }
            div{
                // display: flex;
                // border-radius: 5px;
                // justify-content: center;
                
                .select_guest{
                    div{
                        margin-left: 5px;
                        width: 135px;
                        color: #0B1339;
                        border-radius: 5px; 
                    }
                    .ant-select-arrow{
                        color: #FDB415;
                    } 
                }

                .ant-picker-input {
                    .ant-picker-suffix {
                        color: #FDB415;
                    }
                }

                .select_date{
                    margin-left: 5px;
                    width: 135px;
                    color: #0B1339;
                    border-radius: 5px;
                    height: 32px;
                    div{
                        input{
                            color: #0B1339;
                        }
                    }
                    .ant-picker-clear{
                        color: #FDB415;
                    }
                }

                .select_time{
                    // margin-left: 5px;
                    width: 155px;
                    // border: 1px solid #0B1339;
                    color: #0B1339;
                    border-radius: 5px;
                    height: 54px;
                    // padding: 16px 10px;

                    div{
                        input{
                            color: #0B1339;
                        }
                    }
                    .ant-select-arrow{
                        color: #FDB415;
                        right: 16px;
                    }

                    .ant-select-selection-item{
                        padding-left: 15px;
                    }

                    .ant-select-selector {
                        border: 0;
                        padding: 0;
                        font-size: 17px;
                        font-weight: 500;
                        box-shadow: none;
                        background: transparent;

                        height: 100%;
                        display: flex;
                        align-items: center;
                    }
                }

                .select_date_home{
                    cursor: pointer;
                    margin-right: 5px;
                    width: 195px;
                    color: #0B1339;
                    border-radius: 5px;
                    // height: 32px;
                    padding: 14px 10px;
                    div{
                        input{
                            color: #0B1339;
                        }
                    }
                    .ant-picker-clear{
                        color: #FDB415;
                    }
                }

                .select_time_pickup{
                    margin-left: 5px;
                    width: 195px;
                    // border: 1px solid #0B1339;
                    color: #0B1339;
                    border-radius: 5px;
                    height: 32px;

                    div{
                        input{
                            color: #0B1339;
                        }
                    }
                    .ant-select-arrow{
                        color: #FDB415;
                    } 
                }

                .search{
                    // margin-left: 5px;
                    width: 295px;
                    // border: 1px solid #0B1339;
                    color: #0B1339;
                    border-radius: 5px;
                    font-size: 18px;
                    box-shadow: none;
                    background: transparent;
                    padding: 12px 10px;
                    padding-left: 16px;
                    font-weight: 500;
                }

                .search_btn{
                    // width: 82%;
                    // margin-left: 14px;
                    // background: #0B1339;
                    // border-radius: 5px;
                    // height: 45px;
                    // color: #FFFFFF;
                    font-size: 20px;
                    height: 100%;
                    width: 150px;
                    border-radius: 0;
                    // padding: 0 20px;
                    // font-weight: 500;

                    @media (max-width: 767px) {
                        margin-left: 0;
                        // margin-bottom: 20px;
                        // margin-top: 20px;
                        width: 100%;
                        font-size: 20px;
                    }
                }
            }

            .search_content_item{
                .ant-form-item-explain{
                    div{
                        padding-left: 5px !important;
                    }
                }

                .ant-form-item-control-input-content {
                    padding-top: 16px;
                }

                .date-display{
                    position: absolute;
                    z-index: 1;
                    left: 0;
                    pointer-events: none;
                    font-size: 18px;
                    width: 100%;
                    height: 100%;
                    font-weight: 500;
                    padding-left: 16px;
                    background: transparent;
                    padding-right: 35px;
                    text-overflow: ellipsis;
                }

                input {
                    border: 0;
                }

                .ant-picker-focused{
                    border: 0;
                    box-shadow: none;

                    .ant-picker-suffix,
                    .ant-picker-clear {
                        background: #E9E9F9;
                    }
                }

                .select_date_home{
                    border: 0;
                    color: white;
                    width: 250px;
                    background: transparent;
                    input {
                        cursor: pointer;
                        color: transparent;
                        user-select: none;
                        pointer-events: none;
                    }
                }
                .ant-form-item{
                    margin: 0;
                    @media (max-width: 767px) {
                        width: 100%;
                    }
                }
                .ant-col:not(:last-child):focus-within{
                    background: rgba(#FDB415, 0.1); // #E9E9F9;
                }

                .ant-col:last-child {
                    flex-grow: 1;
                }

                .search-custom-label{
                    position: absolute;
                    top: -5px;
                    left: 15px;
                    z-index: 1;

                    &.time {
                        top: 12px;
                    }

                    &.date {
                        left: 16px;
                    }
                }

                .ant-col + .ant-col {
                    border-left: 1px solid #E9E9F9;
                }

                @media (max-width: 767px) {
                    .ant-col {
                        width: 100%;
                    }
                }
            }
        }
  
    }

    @media only screen and (max-width : 900px) {
        margin: 0 15px;
        .search_web{
            display: block;
        }
        .search_mobile{
            display: none;
        }

        .home_search{
           
            .search_content{
                justify-content: center;
                div{
                    .search{
                        margin-left:unset;
                    }
                    .select_guest{
                        div{
                            margin-left:unset;
                        }
                    }
                }
                .search_content_item{
                    flex-direction: column;
                    .select_guest{
                        width: 100%;
                      div{
                          width: 100%;
                      }
                    }
                }
                .select_date{
                    margin-left: unset !important;
                    width: 100% !important;
                }
                .select_time{
                    margin-left: unset !important;
                    width: 100% !important;
                }
                .select_date_home{
                    margin-left: unset !important;
                    width: 100% !important;
                    margin-right: unset;
                }
                .select_time_pickup{
                    margin-left: unset !important;
                    width: 100% !important;
                }
                .search{
                    width: 100% !important;
                }

                form{
                    width: 100%;
                }
            }
      
        }
    }

    @media only screen and (max-width : 768px) {
        margin: unset;
        .search_web{
            display: none;
        }
        .search_mobile{
            display: block;
        }
        .homeSearch{
            border-bottom: 1px solid #FDB415 !important;
            height: 8vh;
        }
        
    }
    
}
