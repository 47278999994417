.home{
    min-height: 75vh;
    overflow: hidden;
    position: relative;

    .hr{
        background-color: #ffffff;
        display: flex;
        margin: 10px 25px 0 25px;
        justify-content: center;
        .nearby_hr{
            background-color: #ffffff;
            padding-top: 15px;
            width: 890px;
            display: flex;
            justify-content: center;
        
               span{
                    width: 190px;
                    padding: 0 5px;
                    font-style: normal;
                    font-weight: 600;
                    font-size: 13.82px;
                    line-height: 17px;
                    color: #0B1339;
               }
               a{
                width: 80px;
                padding-top: 14px;
                padding-left: 5px;
                color: #FDB415;
               }
         }

        .most_popular_hr{
            width: 890px;
            display: flex;
            justify-content: center;
            background-color: #ffffff;
        
               span{
                    width: 130px;
                    padding: 0 5px;
                    font-style: normal;
                    font-weight: 600;
                    font-size: 13.82px;
                    line-height: 17px;
                    color: #0B1339;
               }
               a{
                width: 80px;
                padding-top: 14px;
                padding-left: 5px;
                color: #FDB415;
               };
        }

        a{
            padding-top: 14px;
            padding-left: 5px;
            color: #FDB415;
           }
        @media only screen and (max-width : 900px) {
            a{
                width: 125px;
           }
            
        }

        @media only screen and (max-width : 600px) {

            a{
                width: 150px;
               }
        }


        @media only screen and (max-width : 480px) {
            margin: 10px 10px 0 10px;

            a{
                width: 200px;
               }
        }

        @media only screen and (max-width : 375px) {
           
            .most_popular_hr{
                margin-left: -20px;
                width: 700px;
               span{
                    font-size: 12px;
                    padding: 0;
               }
            }
            a{
                font-size: 12px;
                padding-top: 18px;
           }
        }

        
    }

    .see_more_content{
        background-color: #ffffff;
        justify-content: center;
        display: flex;
        margin: 0 25px;
        .see_more{
            cursor: pointer;
            div{
                font-style: normal;
                font-weight: 600;
                font-size: 18px;
                line-height: 18px;
                color: #FDB415;
                display: flex;
                flex-direction: column;
                .icon{
                    color:  #FDB415;
                }
            }
        }
    }

    .coming_soon{
        display: flex;
        justify-content: center;
        font-size: 40px;
        font-weight: 700;
        color: #FDB415;
        span{
            position: absolute;
            background-color: #ffffff;
        }
    }
    

    @media only screen and (max-width : 500px) {
        .home_banner{
            .home_banner_img{
                padding: 0;
            }
        }
        .see_more_content{
            margin-top: 25px;
        }
    }


    .home_footer{
        background-color: #ffffff;
        justify-content: center;
        display: flex;
        margin: 20px 0; 
        div{
            font-style: normal;
            font-weight: normal;
            font-size: 12px;
            line-height: 15px;
            text-align: center;
            color: #000000;
        }
        a{
            padding-left: 5px;
            font-style: normal;
            font-weight: 600;
            font-size: 12px;
            line-height: 15px;
            text-align: center;
            color: #FDB415;

        }
    }

    
}


.home_banner_footer{
    max-width: 877px;
    margin: 30px auto;
    background: url(../../img/home_footer.png);
    background-repeat:no-repeat;
    background-position: 0 -110px;
    background-size: cover;
    height: 260px;
    color: #ffffff;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    position: relative;
    overflow: hidden;

    h2 {
        font-size: 35px;
        color: #ffffff;
        margin-bottom: 0;
    }

    @media (max-width: 767px) {
        text-align:center;
        padding: 0 20px;
        background-position: center;
    }
}

.promotional_banner_footer_web{
    display: block;
    max-width: 877px;
    margin: 50px auto 50px auto!important;
    background: url(../../img/promotional_banner.jpg);
    background-repeat:no-repeat;
    background-size: cover;
    background-color: #0B1339;
    color: #ffffff;
    flex-direction: column;
    justify-content: center;

    position: relative;
    overflow: hidden;

    h2 {
        font-size: 35px;
        color: #ffffff;
        margin-bottom: 12px;
        line-height: 35px;
    }

    .text_content{
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 260px;
    }

    @media (max-width: 767px) {
        text-align:center;
        padding: 0 20px;
        background-position: center;
    }
}

.promotional_banner_footer_mobile{
    display: none;
}

.filtered::before {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 4;
    content: '';
    background-color: rgba(0,0,0,0.5);
}
// END 

.ant-divider-horizontal.ant-divider-with-text-center::before,
.ant-divider-horizontal.ant-divider-with-text-center::after
{
   border-top: 1px solid #FDB415 !important;
}

.ant-divider-horizontal.ant-divider-with-text-left::before{
    border-top: 1px solid #FDB415 !important;
    width: 0% !important;
}
.ant-divider-horizontal.ant-divider-with-text-left::after
{
    border-top: 1px solid #FDB415 !important;
}
.ant-select-arrow {
    color: #FDB415 !important;
}

// ADDED : 3/3/2021
.homeSearch{
    border: 0 !important;
    box-shadow: none !important;
    border-right: 1px solid #E5E5E5 !important;
    // margin: 10px 0;
    padding-left: 16px !important;
   input {
    font-weight: 500;
    font-size: 18px;
   }
}

@media (max-width: 767px) {
    .promotional_banner_footer_web{
        background-position: -24px 0px !important;
        display: block;
    }
    .promotional_banner_footer_mobile{
        display: none;
    }
}

@media (max-width: 660px) {
    .promotional_banner_footer_web{
        display: none;
    }
    .promotional_banner_footer_mobile{
        display: block;
        background-position: 0px -480px !important;
        height: 400px;
        margin: 50px auto 50px auto!important;
        background: url(../../img/promotion_banner_mobile.jpg);
        background-repeat:no-repeat;
        background-size: cover;
        background-position: center;
        color: #ffffff;
        position: relative;
        overflow: hidden;
        display: flex !important;
        justify-content: center;

        h2 {
            text-align: center;
            font-size: 35px;
            color: #ffffff;
            margin-bottom: 12px;
            line-height: 35px;
            margin-top: 30px;
        }
        p{
            text-align: center;
        }
        .promo_btn_content{
            display: flex;
            justify-content: center;
        }
    }
}

@media (max-width: 655px) {
    .promotional_banner_footer_web{
        display: none;
    }
    .promotional_banner_footer_mobile{
        display: block;
        background-position: center -470px !important;
    }
}

@media (max-width: 635px) {
    .promotional_banner_footer_web{
        display: none;
    }
    .promotional_banner_footer_mobile{
        display: block;
        background-position: center -450px !important;
    }
}

@media (max-width: 620px) {
    .promotional_banner_footer_web{
        display: none;
    }
    .promotional_banner_footer_mobile{
        display: block;
        background-position: center -435px !important;
    }
}

@media (max-width: 600px) {
    .promotional_banner_footer_web{
        display: none;
    }
    .promotional_banner_footer_mobile{
        display: block;
        background-position: center -410px !important;
    }
}

@media (max-width: 580px) {
    .promotional_banner_footer_web{
        display: none;
    }
    .promotional_banner_footer_mobile{
        display: block;
        background-position: center -390px !important;
    }
}

@media (max-width: 555px) {
    .promotional_banner_footer_web{
        display: none;
    }
    .promotional_banner_footer_mobile{
        display: block;
        background-position: center -370px !important;
    }
}

@media (max-width: 540px) {
    .promotional_banner_footer_web{
        display: none;
    }
    .promotional_banner_footer_mobile{
        display: block;
        background-position: center -350px !important;
    }
}

@media (max-width: 520px) {
    .promotional_banner_footer_web{
        display: none;
    }
    .promotional_banner_footer_mobile{
        display: block;
        background-position: center -325px !important;
    }
}

@media (max-width: 490px) {
    .promotional_banner_footer_web{
        display: none;
    }
    .promotional_banner_footer_mobile{
        display: block;
        background-position: center -300px !important;
    }
}

@media (max-width: 480px) {
    .promotional_banner_footer_web{
        display: none;
    }
    .promotional_banner_footer_mobile{
        display: block;
        height: 330px !important;
        background-position: center -280px !important;
    }
}

@media (max-width: 460px) {
    .promotional_banner_footer_web{
        display: none;
    }
    .promotional_banner_footer_mobile{
        display: block;
        background-position: center -260px !important;
    }
}

@media (max-width: 445px) {
    .promotional_banner_footer_web{
        display: none;
    }
    .promotional_banner_footer_mobile{
        display: block;
        height: 400px !important;
        background-position: center -210px !important;

        h2{
            margin-top: 20px;
        }
    }
    .promo_content{
        width: 315px;
    }
}

@media (max-width: 430px) {
    .promotional_banner_footer_web{
        display: none;
    }
    .promotional_banner_footer_mobile{
        display: block;
        background-position: center -180px !important;

        h2{
            margin-top: 20px;
        }
    }
}

@media (max-width: 415px) {
    .promotional_banner_footer_web{
        display: none;
    }
    .promotional_banner_footer_mobile{
        display: block;
        background-position: center -175px !important;

        h2{
            margin-top: 20px;
        }
    }
}

@media (max-width: 395px) {
    .promotional_banner_footer_web{
        display: none;
    }
    .promotional_banner_footer_mobile{
        display: block;
        background-position: center -150px !important;

        h2{
            margin-top: 20px;
        }
    }
}

@media (max-width: 372px) {
    .promotional_banner_footer_web{
        display: none;
    }
    .promotional_banner_footer_mobile{
        display: block;
        background-position: center -130px !important;

        h2{
            margin-top: 20px;
        }
    }
}

@media (max-width: 355px) {
    .promotional_banner_footer_web{
        display: none;
    }
    .promotional_banner_footer_mobile{
        display: block;
        background-position: center -110px !important;

        h2{
            margin-top: 20px;
        }
    }
}

@media (max-width: 335px) {
    .promotional_banner_footer_web{
        display: none;
    }
    .promotional_banner_footer_mobile{
        display: block;
        background-position: center -90px !important;

        h2{
            margin-top: 20px;
        }
    }
}