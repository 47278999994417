.order-form-mobile{
    position: fixed;
    bottom: 0;
    z-index: 100;
    background: #ffffff;
    width: 100%;
    padding-bottom: 20px;
    display: none;

    z-index: 999999;

    margin-bottom: -220px;

    transition: all 200ms ease-in-out;

    &.active{
        margin-bottom: 0;
    }

    &.active::after {
        position: fixed;
        width: 100%;
        height: 100%;
        content: "";
        top: 0;
        z-index: -1;
    }

    @at-root #{&}__header{
        display: flex;
        margin-top: -52px;
        
        .service-tab {
            padding : 12px 10px;
            background: #E9E9F9;
            border-top-left-radius: 10px;
            border-top-right-radius: 10px;
            border: 1px solid #E9E9F9;
            border-bottom: 0;
            width: 130px;
            font-size: 18px;
            font-weight: 500;

            &:first-child{
                padding-right: 20px;
                border-top-right-radius: 0;
            }

            &:nth-child(2){
                margin-left: -10px;
            }

            &.tab_active{
                background: #FDB415;
            }

            * {
                pointer-events: none;
            }
        }
    }

    @media (max-width: 767px) {
        display: block;
    }

    @at-root #{&}__content{
        .submit_btn{
            width: 100%;
            height: auto;
            padding: 8px 0;
            font-size: 18px;
            font-weight: bold;
        }

        .ant-row:not(:last-child):focus-within{
            background: rgba(#FDB415, 0.1);
        }
    }

    .ant-form-item-control-input-content {
        padding-top: 16px;
    }

    .date-display{
        position: absolute;
        z-index: 1;
        left: 0;
        pointer-events: none;
        font-size: 18px;
        width: 100%;
        height: 100%;
        font-weight: 500;
        background: transparent;
        padding: 0;
        padding-right: 35px;
        text-overflow: ellipsis;

        &::placeholder {
            color: #bfbfbf;
        }
    }

    input {
        border: 0;
    }

    .ant-picker-focused{
        border: 0;
        box-shadow: none;

        .ant-picker-suffix,
        .ant-picker-clear {
            background: #E9E9F9;
        }
    }

    .select_date_home{
        border: 0;
        padding-left: 0;
        color: white;
        width: 100%;
        height: 52px;
        background: transparent;
        input {
            cursor: pointer;
            color: transparent;
        }
    }

    .select_time{
        width: 100%;
        color: #0B1339;
        border-radius: 5px;
        height: 52px;

        div{
            input{
                color: #0B1339;
            }
        }
        .ant-select-arrow{
            color: #FDB415;
            right: 13px;
        }

        .ant-select-selector {
            border: 0 !important;
            padding: 0  !important;
            font-size: 18px;
            font-weight: 500;
            box-shadow: none !important;
            background: transparent !important;

            height: 100%  !important;
            display: flex;
            align-items: center;
        }
    }
    .ant-form-item{
        margin: 0;
        width: 100%;
    }

    .search-custom-label{
        position: absolute;
        top: -5px;
        left: 15px;
        z-index: 1;

        &.time {
            top: 12px;
        }

        &.date {
            left: 0;
        }
    }

    .ant-col {
        width: 100%;
        padding: 0 16px;
    }

    .ant-btn {
        span {
            pointer-events: none;
        }
    }
}