.privacy-policy{
    margin-top: -12px;
    @at-root #{&}__banner{
        background: #FDB415;
        img {
            height: 14vh;
        }

        padding: 20px 0;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .privacy-policy__content,
    .privacy-policy__content-merchant,
    .privacy-policy__content-customer {
        display: flex;
        flex-direction: column;

        .content-container{
           text-align: center;
           max-width: 800px;
           margin: 0 auto;

           @media (max-width: 767px) {
            padding: 0 20px;
           }
           
            .content-header {
                font-size: 40px;
                color: #0B1339;
                font-weight: bold;
                margin-bottom: 40px;
            }

            .content-text{
                margin-top: 20px;
                text-align: left;

                @media (max-width: 767px) {
                    text-align: left;
                }

                .underline-decor{
                    text-decoration: underline;
                }
            }

            .indent{
                padding-left: 20px;
            }

            .sub-content-text{
                margin-top: 20px;
                text-align: left;
                font-weight: 500;
                font-size: 18px;

                @media (max-width: 767px) {
                    text-align: left;
                }
            }

            table, th, td {
                border:1px solid black;
                padding: 10px;
            }

            table{
                margin-top: 30px;
            }

            a{
                color: #1255cc;
                text-decoration: underline;
                line-height: 30px;
                
            }

            .bullet-content-text{
                padding-left: 20px;
                margin-top: 20px;
                display: flex;
                text-align: left;
                .bullet {
                    margin-right: 10px;
                    font-size: 12px;
                    margin-top: 2px;
                }
            }

            .content-sections {
                display: flex;
                flex-wrap: wrap;

               
                &.m-reverse {
                    @media (max-width: 767px) {
                        flex-direction: column-reverse;
                    }
                }

                .content-section--img,
                .content-section--detail {
                    flex-grow: 1;
                    flex-basis: 0;
                    max-height: 250px;
                    overflow: visible;

                    @media (max-width: 767px) {
                        width: 100%;
                        flex-basis: auto;
                        max-height: none;
                    }
                }

                .left-pos {
                    margin-right: 20px;
                }

                .right-pos {
                    margin-left: 20px;
                }

                @media (max-width: 767px) {
                    .right-pos,
                    .left-pos {
                        margin: 0;
                    }
                }

                .content-section--detail {
                    text-align: left;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                }

                .content-section--img {
                    display: flex;
                    // justify-content: center;
                    align-items: center;
                    img {
                        width: 100%
                    }

                    @media (max-width: 767px) {
                        margin-top:30px;
                    }
                }

            }
        }
    }

    @at-root #{&}__content {
        padding: 40px 0;
        padding-bottom: 60px;

        @media (max-width: 767px) {
            padding-bottom: 0;
        }
    }

    @at-root #{&}__content-privacy-policy {
        margin-bottom: 60px;
    }

    .privacy-policy__content-merchant,
    .privacy-policy__content-customer {
        margin: 60px 0;
        background: #e8e8e8;
        padding: 30px 0;

        @media (max-width: 767px) {
            margin: 0;
            padding: 30px 20px;
        }
    }
    
}