.custom-order{
    // box-shadow: 1px 1px 13px 1px #e7e7e7;
    padding: 0 20px;
    border-radius: 10px;
    &.order-nested-layer{
        padding: 0 0 0 15px;
        .custom-order__header {
            font-size: 15px;
            margin-bottom: 0;
            border-bottom: 1px solid #e7e7e7;
        }
    }

    .nested-list  {
        display: none;
    }
    .display-list {
        display: block;
    }

    @at-root #{&}__header {
        font-size: 18px;
        line-height: 18px;
        font-weight: 600;
        padding-top: 10px;
        padding-bottom: 5px;
        margin: 0;
        border-bottom: 2px solid #e7e7e7;
        margin-bottom: 10px;
        span {
            font-size: 12px;
        }

        .font-11 {
            font-size: 10px;
        }

        &.required::before {
            display: inline-block;
            margin-right: 4px;
            color: #ff4d4f;
            font-size: 14px;
            font-family: SimSun, sans-serif;
            line-height: 1;
            content: '*';

            position: relative;
            top: -5px;
        }
    }

    .custom-label-coontainer {
        display: flex;
        align-items: center;
        padding: 10px 0;
        border-bottom: 1px solid #e7e7e7;

        .opt-custom-label{
            width: calc(100% - 22px);
            font-size: 14px;
            font-weight: 600;
            display: flex;
            justify-content: space-between;
            align-items: center;
            &.opt-custom-label_icon {
                justify-content: normal;
                .header_icon {
                    margin-left: 10px;
                }
            }
    
        }

        .opt-custom-label_price{
            font-size: 14px;
            font-weight: 600;
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        .ant-checkbox-wrapper {
            margin-right: 8px;
        }
    }

    .ant-radio-checked .ant-radio-inner{
        border-color: #FDB415 !important ;
      }
      
      .ant-radio-checked .ant-radio-inner:after{
        background-color: #FDB415;
      }
      
      .ant-radio:hover .ant-radio-inner {
        border-color: #FDB415 ;
      }
}

.custom-order + .custom-order {
    margin-top: 16px;
}