.requiredLabel::after{
    color: #ff4d4f;
    content: '*';
    margin-left: 4px;
}

.capitalize {
    text-transform: capitalize;
}

.error-modal {
    .ant-btn-primary {
        background-color: #FDB415;
        border-color: #FDB415;
    }
    .ant-btn-primary:hover, .ant-btn-primary:focus {
        background-color: #FDB415;
        border-color: #FDB415;
    }
}

.field-error{
    color: #ff4d4f;
    font-size: 14px;
}