$prime: #1890ff !default;
.reset-password-form{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 45px;
    margin-bottom: 45px;
    min-height: 65vh;

    .reset-password-header{
        span{
            font-weight: bold;
            font-size: 20px;
            color: #0B1339;
        }
    }

    .reset-password-subheader{
        font-size: 15px;
        color: #0B1339;
        padding-bottom: 25px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding-top: 10px;
    }

    .reset-password-subfooter{
        font-size: 15px;
        color: #0B1339;
        margin-top: 5px;
        a{
            color: #FDB415;
            padding-left: 5px;
        }
    }

    form {
        padding: 0 25px 20px 25px;
        display: flex;
        flex-direction: column;

        .form-input{
            margin-top: 10px;
        }

        .ant-form-item {
            margin: 7px 0;
            margin-bottom: 15px;
        }
        
        .from-item-bottom {
            margin-bottom: 10px !important;
        }

        input{
            padding: 10px;
            width: 324px;
            height: 50px;
            background: #FFFFFF;
            border: 1px solid #0B1339;
            box-sizing: border-box;
            border-radius: 5px;
        }

        .submit_btn{
            width: 324px;
            height: 50px;
            background-color: #FDB415;
            border-color: #FDB415;
            border-radius: 5px;
            font-style: normal;
            font-weight: 600;
            font-size: 15px;
            line-height: 18px;
            color: #FFFFFF;
        }

    }

    @media only screen and (max-width : 480px) {

        form{
            width: 100%;
            padding: 0 40px;
            input{
                width: 100%;
            }
            .submit_btn{
                width: 100%;
            }
        }
    }

    @media only screen and (max-width : 400px) {

        form{
            padding: 0 20px;
        }
    }
}