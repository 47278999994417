.contact-us{
    margin-top: -12px;
    .contact-us__content,
    .contact-us__content-customer {
        display: flex;
        flex-direction: column;
        margin: 0 25px;

        .content-container{
           text-align: center;
           max-width: 730px;
           margin: 0 auto;
           margin-top: 5vh;
           
            .content-header {
                font-size: 40px;
                color: #0B1339;
                font-weight: bold;
                border-bottom: 1.5px solid #C4C4C4;
                padding-bottom: 20px;
            }

            .content-text{
                margin-top: 20px;
                font-size: 14px;
                color: #576793;
                font-size: 16px;

                .talk-now{
                    font-weight: 800;
                    color: #0B1339;
                    font-size: 20px;
                    margin-bottom: 5px;
                }

            }

            .content-support{
                margin-top: 20px;
                display: flex;
                flex-direction: column;
                justify-content: center;

                .supports{
                    color: #FDB415;
                    display: flex;
                    justify-content: space-between;
                    margin-top: 5px;
                    flex-wrap: wrap;
                }
                
                .social-media-supports{
                    padding-top: 10px;
                    color: #FDB415;
                    display: flex;
                    justify-content: space-evenly;
                    margin-top: 5px;
                    flex-wrap: wrap;
                }
                .supports-item{
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-size: 16px;
                
                    .icon{
                        color: #ffffff;
                        width: 27px;
                        height: 26px;
                        font-size: 16px;
                        text-align: center;
                        background: #FDB415;
                        border-radius: 50px;
                        margin-right: 1vh;
                        justify-content: center;
                        display: flex;
                        align-items: center;
                        align-content: center;
                    }
                }

                @media only screen and (max-width : 768px) {
                   .supports{
                       flex-direction: column !important;
                   }

                   .social-media-supports{
                    flex-direction: column !important;
                    margin-top: unset;
                    padding-top: unset;
                    }

                   .supports-item{
                       margin-bottom: 15px;
                   }
                }
            }
        }
    }
    
}