
// BORDER COLOR
$b-gray   : #ccc;
$b-prime  : #FDB415;

$c-white  : #ffffff;

.driver-tipper{
    list-style: none;
    padding: 0;
    margin: 10px 0;

    font-size: 14px;

    border: 1px solid $b-gray;
    // overflow: hidden;
    border-radius: 5px;

    display: flex;

    li {
        min-width: 55px;
        padding: 3px;
        text-align: center;
        cursor: pointer;
        user-select: none;

        &:not(.active):hover {
            color: $b-prime;
        }

        &.active {
            color: $b-prime;
            font-weight: bold;
        }
    }

    li + li {
        border-left: 1px solid $b-gray;
    }
}