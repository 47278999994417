.button-text{
    color: #FDB415;
    cursor: pointer;
    &:active{
        color: #FDB415;
    }

    &:hover{
        color: #FDB415;
    }
}

.button-text-primary{
    color: #1890ff;
    cursor: pointer;
    &:active{
        color: #1890ff;
    }

    &:hover{
        color: #1890ff;
    }
}
