$white: #ffffff !default;

.header_web{
    display: block;
}

.header_mobile{
    display: none;
}

.header_menu{
    width: 300px;
    .order-history{
        color: #000000D9;
    }

    .mobile-menu-border {
        padding: 5px 12px;

        a {
            margin: 0;
            padding: 0;
            padding-left: 10px;
            border-left: 1px solid #ccc;
        }
    }
}

.header{
    display: flex;
    background: #0B1339;
    color: #ffffff;
    position: relative;
    justify-content: space-between;
    height: 75px;
    
    @at-root #{&}__left{
        display: flex;
        width: 40%;
        .header_tab{
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            padding: 0 18px;
            font-size: 14px;
            
            img{
                width: 55px;
            }
            a{
                color: white;
            }
           
        }

        .header__logo{
            .logo_img{
                width: 15vh;
                img{
                    width: 100%;
                }
            }
        }
    }

    @at-root #{&}__logo{
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 18px;
        @media (max-width: 767px) {
            padding-left: 14px;
        }
    }

   
    @at-root #{&}__right{
        display: flex;
        width: 40%;
        justify-content: flex-end;
        .active {
            border-bottom: 4px solid #FDB415;
        }
        .user_tab{
            
            font-size: 22px;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            padding: 0 18px;
            margin-right: 4vh;
   
            a{
                color: white;
            }

            .user_image_frame{
                margin-right: 16px;
                border: 2px solid #FDB415;
                color: #FDB415;
                border-radius: 50px;
                width: 43px;
                height: 43px;
                display: flex;
                justify-content: center;
                align-items: center;

                img{
                    border-radius: 50px;
                    width: 32px;
                    height: 32px;
                    object-fit: cover;
                }
            }
               
   
        }
        .header_tab{
            font-size: 14px;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            padding: 0 18px;
           
            a{
                color: white;
            }
            .header_button{
                background-color: #FDB415;
                color: #0B1339;
                display: flex;
                justify-content: center;
                align-items: center;
                height: 35px;
                width: 93.5px;
                border-radius: 5px;
                a{
                    color: #0B1339;
                }
            }

            .ant-dropdown-menu {
                width: 300px;
            }
        }
    }

    @at-root #{&}__center{
        display: flex;
        width: 20%;
        justify-content: center;
        // margin-left: -100px;
        .header_tab{
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            padding: 0 5px;
            font-style: italic;
            font-size: 14px;
            a{
                color: white;
            }
        }

        .header_icon{
            color: #FDB415;
            padding: 0 5px;
        }

        @media (max-width: 860px) {
            padding-right: 10px;
        }
    }
    @media only screen and (max-width : 860px) {
        @at-root #{&}__center{
            margin-left: unset;
            width: unset;
        }
    }
}

@media only screen and (max-width : 860px) {
    .header_web{
        display: none;
    }
    .header_mobile{
        display: block;
        .header__logo{
            .logo_img{
                width: 10vh;
                padding-left: 4px;
                img{
                    width: 100%;
                }
            }
            
        }
    }
}

@media only screen and (max-width : 480px) {
    .header_menu{
        // width: 200px;
        .order-history{
            color: #000000D9;
        }
    }
}